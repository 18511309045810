<template>
  <div class="container">
    <div class="main">
      <breadcrumb :items="currentLocation" />
      <div class="top-boxes" v-loading="loading">
        <div class="content-box recommend" v-loading="recommendLoading">
          <div class="top">
            <h4>图书推荐</h4>
            <span class="more" @click="moreStar">更多</span>
          </div>
          <div class="tabs">
            <span
              v-for="(tab, idx) in bookList.book_user_star_subject"
              :key="tab.name"
              @click="switchRecommend(tab, idx)"
              :class="{ 'active-tab': idx === recommendIdx }"
              v-show="tab.name == '全部' || tab.result > 0"
            >
              {{ tab.name }}
            </span>
          </div>
          <no-content v-if="emptyList.recommend" />
          <div class="books" v-else>
            <book-info
              v-for="book in bookList.book_user_star_recordlist"
              :key="book.ruid"
              :bookInfo="book"
            />
          </div>
        </div>
        <div class="content-box favorite">
          <div class="top">
            <h4>收藏排行</h4>
            <span class="more" />
          </div>
          <rank-list :list="bookList.book_user_booklist_recordlist" />
        </div>
      </div>
      <div
        class="content-box"
        :class="activeTab === 0 ? 'latest' : 'discount'"
        v-loading="loading || discountLoading"
      >
        <div class="top">
          <div class="discount-tab">
            <span @click="changeTab(0)" :class="{ active: activeTab == 0 }">
              最新上架
            </span>
            <span @click="changeTab(1)" :class="{ active: activeTab == 1 }">
              特价图书
            </span>
          </div>
          <!-- <span class="more" @click="activeTab ? moreDiscount : moreNewest">更多</span> -->
          <span class="more" @click="moreItems(activeTab)">更多</span>
        </div>
        <template v-if="activeTab === 0">
          <div class="tabs">
            <span
              v-for="(tab, idx) in bookList.book_latest_subject"
              :key="tab.name"
              @click="switchLatest(tab, idx)"
              :class="{ 'active-tab': idx === latestIdx }"
              v-show="tab.name == '全部' || tab.result > 0"
            >
              {{ tab.name }}
            </span>
          </div>
          <no-content v-if="emptyList.latest" />
          <div class="books" v-else>
            <book-info
              v-for="book in bookList.book_latest_recordlist"
              :key="book.ruid"
              :bookInfo="book"
              :showPrice="true"
            />
          </div>
        </template>
        <template v-if="activeTab === 1">
          <div class="tabs">
            <span
              v-for="(tab, idx) in bookList.book_discount_subject"
              :key="tab.name"
              @click="switchDiscount(tab, idx)"
              :class="{ 'active-tab': idx === discountIdx }"
              v-show="tab.name == '全部' || tab.result > 0"
            >
              {{ tab.name }}
            </span>
          </div>
          <no-content v-if="emptyList.discount" />
          <div class="books" v-else>
            <book-info
              v-for="book in bookList.book_discount_recordlist"
              :key="book.ruid"
              :bookInfo="book"
              :showPrice="true"
              :discount="true"
            />
          </div>
        </template>
      </div>
      <div class="content-box database" v-loading="loading">
        <div class="top">
          <h4>数据库</h4>
          <span class="more" @click="toDataList">更多</span>
        </div>
        <div class="database-list">
          <no-content v-if="emptyList.database" />
          <template v-else>
            <database-info
              v-for="item in bookList.ebook_collection_recordlist"
              :key="item.ruid"
              :info="item"
            />
          </template>
        </div>
      </div>
      <div class="content-box data-count" v-loading="loading">
        <div class="top">
          <h4>数据统计</h4>
          <span class="more"></span>
        </div>
        <div class="units">
          <div class="unit" v-for="unit in bookList.bottom_count" :key="unit.name">
            <p class="unit-count">{{ unit.count }}</p>
            <p class="unit-name">{{ unit.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from 'components/Breadcrumb'
import BookInfo from 'components/BookInfo'
import NoContent from 'components/NoContent'
import RankList from './RankList'
import DatabaseInfo from './DatabaseInfo'

export default {
  name: '',
  data() {
    return {
      currentLocation: [{ name: '资源首页', path: '' }],
      bookCount: [],
      bookList: [],
      activeTab: 0,
      recommendIdx: 0,
      latestIdx: 0,
      discountIdx: 0,
      loading: false,
      recommendLoading: false,
      discountLoading: false,
    }
  },
  computed: {
    emptyList() {
      const list = this.bookList
      return {
        recommend: !list.book_user_star_recordlist ? true : false,
        latest: !list.book_latest_recordlist ? true : false,
        discount: !list.book_discount_recordlist ? true : false,
        database: !list.ebook_collection_recordlist ? true : false,
      }
    },
  },
  methods: {
    toBookList() {
      this.$router.push('BookList')
    },
    changeTab(tab) {
      this.discountLoading = true
      this.activeTab = tab
      this.latestIdx = 0
      this.getLatest('')
      this.switchDiscount({ ruid: '' }, 0)
    },
    moreItems(tab) {
      if (tab) {
        this.moreDiscount()
      } else {
        this.moreNewest()
      }
    },
    moreStar() {
      const filter = {
        logicalop: 'and',
        relationop: 'join',
        join: {
          type: 'dataobjectuserstar',
        },
        children: [
          {
            logicalop: 'and',
            fieldname: 'ruid',
            relationop: 'exists',
            word: '',
          },
        ],
      }
      let specialFilter = {
        home: true,
        name: '图书推荐',
        title: '特殊限定',
        fieldname: '',
        relationop: 'group',
        children: [filter],
      }
      const filters = {
        left: [],
        top: [specialFilter],
        search: [],
      }
      this.setFilter(filters)
    },
    moreDiscount() {
      const filter = [
        {
          name: '特价图书',
          fieldname: '54002',
          relationop: 'exists',
          word: '',
        },
        {
          fieldname: '54003',
          relationop: '>=',
          valuetype: 'D',
          word: [{ itemtype: 'edate' }],
        },
        {
          logicalop: 'and',
          fieldname: 'wfstatus',
          relationop: '=',
          word: 'A',
        },
      ]
      let specialFilter = {
        home: true,
        fieldname: '',
        relationop: 'group',
        name: '特价图书',
        title: '特殊限定',
        children: filter,
      }
      const filters = {
        left: [],
        top: [specialFilter],
        search: [],
      }
      this.setFilter(filters)
    },
    moreNewest() {
      sessionStorage.setItem('newest', 'true')
      this.$router.push('BookList')
    },
    setFilter(filters) {
      sessionStorage.setItem('searchFilter', JSON.stringify(filters))
      this.$router.push('BookList')
    },
    toDataList() {
      this.$router.push('DataList')
    },
    async getAllList() {
      this.loading = true
      try {
        const res = await this.$http.post('/api/v1/datasearch/resource_center_index')
        if (res.cxajaxrc != 0) return false
        let bookList = res.returnvalue
        bookList.bottom_count = bookList.bottom_count.map((unit) => {
          unit.count = this.thousands(unit.count)
          return unit
        })
        this.bookList = bookList
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    thousands(num) {
      var str = num.toString()
      var reg = str.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g
      return str.replace(reg, '$1,')
    },
    switchRecommend(tab, idx) {
      this.recommendLoading = true
      this.recommendIdx = idx
      this.getRecommend(tab.ruid)
    },
    async getRecommend(id) {
      try {
        const res = await this.$http.post(
          '/api/v1/datasearch/resource_center_index' +
            '?refresharea=book_user_star_recordlist&starsubject=' +
            id
        )
        if (res.cxajaxrc != 0) return false
        this.bookList.book_user_star_recordlist =
          res.returnvalue.book_user_star_recordlist
      } catch (e) {
        console.log(e)
      } finally {
        this.recommendLoading = false
      }
    },
    switchLatest(tab, idx) {
      this.discountLoading = true
      this.latestIdx = idx
      this.getLatest(tab.ruid)
    },
    async getLatest(id) {
      try {
        const res = await this.$http.post(
          '/api/v1/datasearch/resource_center_index' +
            '?refresharea=book_latest_recordlist&latestsubject=' +
            id
        )
        if (res.cxajaxrc != 0) return false
        this.bookList.book_latest_recordlist = res.returnvalue.book_latest_recordlist
      } catch (e) {
        console.log(e)
      } finally {
        this.discountLoading = false
      }
    },
    switchDiscount(tab, idx) {
      this.discountLoading = true
      this.discountIdx = idx
      this.getDiscount(tab.ruid)
    },
    async getDiscount(id) {
      try {
        const res = await this.$http.post(
          '/api/v1/datasearch/resource_center_index' +
            '?refresharea=book_discount_recordlist&discountsubject=' +
            id
        )
        if (res.cxajaxrc != 0) return false
        this.bookList.book_discount_recordlist = res.returnvalue.book_discount_recordlist
      } catch (e) {
        console.log(e)
      } finally {
        this.discountLoading = false
      }
    },
  },
  mounted() {
    this.getAllList()
  },
  components: {
    Breadcrumb,
    BookInfo,
    RankList,
    DatabaseInfo,
    NoContent,
  },
}
</script>

<style lang="scss" scoped>
.container {
  background-color: #eeefef;
  .main {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    padding-bottom: 30px;
    > * {
      margin-top: 10px;
      background-color: #fff;
      &:first-child {
        background-color: transparent;
        margin-top: 0;
      }
    }
    .top-boxes {
      display: flex;
      // gap: 10px;
      background-color: #eeefef;
    }
    .content-box {
      padding: 20px;
      background-color: #fff;
      /deep/ .no-content {
        height: 360px;
      }
      .top {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid #efefef;
        h4 {
          margin: 0;
          font-size: 1.2rem;
        }
        .discount-tab {
          span {
            font-size: 1.2rem;
            margin-left: 10px;
            cursor: pointer;
            &:first-child {
              margin-left: 0;
              padding-right: 10px;
              border-right: 1px solid #ddd;
            }
          }
          .active {
            font-weight: bold;
          }
        }
        .more {
          cursor: pointer;
          &:hover {
            color: $mainColor;
          }
        }
      }
      .tabs {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        span {
          cursor: pointer;
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 15px;
          margin-bottom: 5px;
          line-height: 1.5rem;
          &:hover {
            color: $mainColor;
          }
        }
        .active-tab {
          color: $mainColor;
        }
      }
      .books {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
      }
    }
    .recommend {
      width: 790px;
      margin-right: 10px;
      .books {
        padding: 0;
      }
      /deep/ .book {
        max-width: 212px;
        .cover {
          height: 300px;
          width: 212px;
          display: flex;
          align-items: center;
        }
        p {
          max-width: 212px;
        }
      }
    }
    .favorite {
      width: 400px;
    }
    .discount {
      .books {
        display: flex;
        justify-content: space-between;
        min-width: 0;
        /deep/ .sub-title {
          max-width: 168px;
        }
      }
      /deep/ .book {
        max-width: 170px;
        .cover {
          height: 240px;
          width: 170px;
          display: flex;
          align-items: center;
        }
      }
    }
    .latest {
      .books {
        display: flex;
        justify-content: space-between;
        min-width: 0;
        /deep/ .sub-title {
          max-width: 168px;
        }
      }
      /deep/ .book {
        max-width: 168px;
        .cover {
          height: 260px;
          width: 168px;
          display: flex;
          align-items: center;
        }
      }
    }
    .data-count {
      .units {
        display: flex;
        justify-content: space-between;
        .unit {
          border-right: 1px solid #e6e6e6;
          margin-top: 20px;
          width: 100%;
          text-align: center;
          &:last-child {
            border-right: none;
          }
          .unit-count {
            font-size: 1.5rem;
            color: $mainColor;
          }
          .unit-name {
            margin-top: 10px;
            color: #666;
          }
        }
      }
    }
  }
}
</style>
