<template>
  <div class="item">
    <div class="cover">
      <img :src="getImage" @click="toDetail">
    </div>
    <div class="info" @click="toDetail">
      <div class="info-top">
        <el-tooltip :content="info.name">
          <h4>{{ info.name }}</h4>
        </el-tooltip>
        <span class="package">资源量：{{ info.bookcount }} 学科包：{{ info.packagecount }} 订购单元：{{ info.unitcount }}</span>
      </div>
      <p class="sub-title">{{ info.namecn }}</p>
      <p class="description">{{ info.description }}</p>
    </div>
  </div>
</template>

<script>
import urlSet from '@/requests/url'

export default {
  name: '',
  props: {
    info: Object,
  },
  data() {
    return {
      noImg: require('images/no-image.png'),
      cnki: require('@/assets/images/cnki.png'),
    }
  },
  computed: {
    getImage() {
      const id = this.info.logopath
      if (!id) return this.cnki;
      return id.substring(0, 4) === 'http' ? id : `${urlSet.fileUrl}/api/v1/fileredirect/file?ruid=${id}`;
    },
  },
  methods: {
    toDetail() {
      let url = this.$router.resolve({
        name: 'DataDetail',
        query: { id: this.info.ruid }
      })
      window.open(url.href, '_blank')
    },
  }
}
</script>

<style lang="scss" scoped>
  .item {
    display: flex;
    margin-top: 20px;
    .cover {
      width: 320px;
      min-width: 320px;
      height: 140px;
      border: 1px solid #cfcfcf;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      img {
        // width: 100%;
        // height: 100%;
        max-width: 318px;
        max-height: 138px;
        width: auto;
        height: auto;
      }
    }
    .info {
      background-color: #eee;
      width: 100%;
      margin-left: 10px;
      padding-left: 20px;
      cursor: pointer;
      .info-top {
        display: flex;
        justify-content: space-between;
        h4 {
          max-width: 500px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 1.2rem;
          margin-top: 20px;
          &:hover {
            color: $mainColor;
          }
        }
        .package {
          background-image: url('~@/assets/images/packages.png');
          background-repeat: no-repeat;
          background-size: cover;
          color: #fff;
          padding: 10px 20px;
        }
      }
      .sub-title {
        font-size: 1.1rem;
        margin-top: 8px;
        color: #666;
      }
      .description {
        margin-top: 10px;
        padding-right: 20px;
        padding-bottom: 20px;
        color: #666;
      }
    }
  }
</style>
