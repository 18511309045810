<template>
  <div class="rank-list">
    <div class="rank-item" v-for="(item, index) in list" :key="item.ruid">
      <div class="number-box" :class="getBgc(index+1)">
        <p class="rank-number">{{ index + 1 }}</p>
      </div>
      <div class="title-box" @click="toDetail(index)">
        <el-tooltip :content="item.title">
          <p class="title" :id="'rank-title' + index">{{ item.title }}</p>
        </el-tooltip>
        <el-tooltip :content="item.titlecn">
          <p class="sub-title">{{ item.titlecn }}</p>
        </el-tooltip>
      </div>
      <div class="fav-count">
        <p class="count">{{ item.userbooklistcount }}</p>
        <p class="text">收藏量</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    list: Array,
  },
  methods: {
    getBgc(index) {
      switch (index) {
        case 1:
          return 'number-one'
        case 2:
          return 'number-two'
        case 3:
          return 'number-three'
      }
    },
    toDetail(index) {
      let url = this.$router.resolve({
        name: 'BookDetail',
        query: { id: this.list[index].ruid }
      });
      window.open(url.href, '_blank')
    },
  }
}
</script>

<style lang="scss" scoped>
  .rank-list {
    /deep/ p {
      margin: 0;
    }
    .rank-item {
      padding: 20px 0;
      display: flex;
      justify-content: space-between;
      font-size: 1.1rem;
      line-height: 1.5rem;
      .number-box {
        width: 45px;
        height: 45px;
        background-color: #bbb;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
      }
      .number-one {
        background-color: #D62F2F;
      }
      .number-two {
        background-color: #FF8400;
      }
      .number-three {
        background-color: #1D9D08;
      }
      .title-box {
        max-width: 260px;
        width: 260px;
        cursor: pointer;
        .title {
          font-weight: bold;
          max-height: 3rem;
          overflow: hidden; 
          text-overflow:ellipsis;
          display:-webkit-box; 
          word-break: break-all;
          -webkit-line-clamp:2; 
          -webkit-box-orient: vertical;
        }
        .sub-title {
          color: #666;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .fav-count {
        text-align: center;
        .count {
          color: $mainColor;
        }
      }
    }
  }
</style>
