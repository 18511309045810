<template>
  <div class="book">
    <div class="cover">
      <img :src="bookCover" @click="toDetail">
    </div>
    <el-tooltip :content="bookInfo.title">
      <p class="title" @click="toDetail">{{ bookInfo.title }}</p>
    </el-tooltip>
    <el-tooltip :content="bookInfo.titlecn" v-if="bookInfo.titlecn">
      <p class="sub-title" @click="toDetail">{{ bookInfo.titlecn }}</p>
    </el-tooltip>
    <span class="price-box" v-if="showPrice && discount">
      <span class="new-price">{{ bookInfo.discountprice }}</span>
      <span class="old-price">{{ bookInfo.cnyprice }}</span>
    </span>
    <span class="price-box" v-else>
      <span class="new-price">{{ bookInfo.cnyprice }}</span>
    </span>
  </div>
</template>

<script>
import urlSet from '@/requests/url'

export default {
  name: '',
  props: {
    bookInfo: Object,
    showPrice: {
      type: Boolean,
      default: false
    },
    discount: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      noImg: require('images/no-image.png'),
    }
  },
  computed: {
    bookCover() {
      const id = this.bookInfo.coverruid
      if (!id) return this.noImg
      return id.substring(0, 4) === 'http' ? id : `${urlSet.fileUrl}/api/v1/fileredirect/file?ruid=${id}`
    },
  },
  methods: {
    toDetail() {
      let url = this.$router.resolve({
        name: 'BookDetail',
        query: { id: this.bookInfo.ruid }
      });
      window.open(url.href, '_blank')
    },
  }
}
</script>

<style lang="scss" scoped>
  .book {
    cursor: pointer;
    .cover {
      border: 1px solid #cfcfcf;
      img {
        width: 100%;
        max-height: 100%;
      }
    }
    .title {
      font-weight: bold;
      line-height: 1.5rem;
      max-height: 3rem;
      overflow: hidden; 
      text-overflow:ellipsis;
      word-break: break-all;
      -webkit-line-clamp:2; 
      -webkit-box-orient: vertical;
      margin-top: 10px;
      &:hover {
        color: $mainColor;
      }
    }
    .sub-title {
      color: #666;
      line-height: 1.5rem;
      max-height: 3rem;
      margin-top: 5px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .price-box {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      font-size: 1.2rem;
      .new-price {
        color: #f00;
        font-weight: bold;
      }
      .old-price {
        color: #666;
        text-decoration: line-through;
      }
    }
  }
</style>
